const config = {
  WS_BASE_URL: "https://app.hirelands.com/api/",
  Data_URL: "https://app.hirelands.com/",
  CVs_URL: "https://app.hirelands.com",
  Video_URL: "https://app.hirelands.com",
  Video_URL_2: "https://videos2.hirelands.com",
  General_Presentation_Job_ID: "626112808a42eb6215ebe7b1",
  Candidature_Method: "postuler_directement_one_step",
  DOMAIN_NAME: 'https://app.hirelands.com',
  DEMO: true,
  Environment: "Prod",
  Jobs_for_outfrSearchPermanentFr: [
    "62ceb733544d9f0ae2a4df3f", 
    "62ce94b1544d9f0ae2a4dee5", 
    "62ce939c544d9f0ae2a4de73", 
    "62ce9066544d9f0ae2a4ddbf", 
    "62ce8e45544d9f0ae2a4dcf1", 
    "62ce8b95544d9f0ae2a4dc5e", 
    "62cd7204544d9f0ae2a4d92b", 
    "62cd88cc544d9f0ae2a4da2e", 
    "62cd9102544d9f0ae2a4db98", 
    "62cd8b0b544d9f0ae2a4db10", 
    "63384082cf27abfe0d834dd5"
  ],
  Default_Job_for_employer_profiles: "62ceb733544d9f0ae2a4df3f",
  Jobs_for_outfrSearchPermanentFr_v2: [
    { value: "62ceb733544d9f0ae2a4df3f", label: "Python" },
    { value: "62ce94b1544d9f0ae2a4dee5", label: "Autres types de profil" },
    { value: "62ce939c544d9f0ae2a4de73", label: "Angular" },
    { value: "62ce9066544d9f0ae2a4ddbf", label: "Réseaux et Sécurité" },
    { value: "62ce8e45544d9f0ae2a4dcf1", label: "Système Linux" },
    { value: "62ce8b95544d9f0ae2a4dc5e", label: "Système Windows" },
    { value: "62cd7204544d9f0ae2a4d92b", label: "Développeur Node MERN" },
    { value: "62cd88cc544d9f0ae2a4da2e", label: "Java" },
    { value: "62cd9102544d9f0ae2a4db98", label: "Dotnet" },
    { value: "62cd8b0b544d9f0ae2a4db10", label: "Big Data" },
    { value: "63384082cf27abfe0d834dd5", label: "Product Owner" },
  ],
  question_types: [
    { value: "Video", label: "Vidéo" },
    { value: "QCM with only one correct answer", label: "QCM avec une seule réponse correcte" },
  ],
  question_QCM_options_for_recruter: [
    { value: "Option 1", label: "Option 1" },
    { value: "Option 2", label: "Option 2" },
    { value: "Option 3", label: "Option 3" },
    { value: "Option 4", label: "Option 4" },
    { value: "Option 5", label: "Option 5" },
    { value: "Option 6", label: "Option 6" },
  ],
  CandidatesUserIds_for_training: [
    { value: "62e8ea745bc2ec2b8c692e68", label: "etranger100" },
  ],
  optionsQuestionCatalogs: [
    { value: "637b9b81c0079d46250b3b1e", label: "Reactjs" },
    { value: "6381e85ab90bd8796e94ddbf", label: "Nodejs" },
    { value: "637751d0c0079d46250abed6", label: ".NET platform" },
    { value: "637dc9537599831512c7b890", label: "Angular 2+" },
    { value: "63813431b90bd8796e94d803", label: "HTML" },
    { value: "637fb937b90bd8796e948767", label: "CSS" },
    { value: "6381e423b90bd8796e94dd30", label: "JavaScript" },
    { value: "637e05bc7599831512c7d046", label: "Ansible" },
    { value: "637ce4c17599831512c7a615", label: "AWS" },
    { value: "637cf6847599831512c7ae07", label: "Agile" },
    { value: "637d0da57599831512c7b496", label: "Android" },
    { value: "6382b22cb90bd8796e94ebde", label: "AngularJS" },
    { value: "637e0c257599831512c7d1ad", label: "Apache Spark" },
    { value: "637f28ca3dc3ad4525435df6", label: "Azure" },
    { value: "637f2e8d3dc3ad4525436089", label: "BDD / Gherkin" },
    { value: "63807e03b90bd8796e94916d", label: "Bash" },
    { value: "637f4d133b6f756d9ecb1fc3", label: "C" },
    { value: "637f8763b90bd8796e947698", label: "C#" },
    { value: "637f8ecfb90bd8796e947a12", label: "C++" },
    { value: "637fbeb0b90bd8796e948863", label: "Cassandra / NoSQL" },
    { value: "637fd356b90bd8796e948a6a", label: "Cyber Security" },
    { value: "63806d0ab90bd8796e948cc2", label: "Data Science" },
    { value: "638a07bf5d1dc2c31758a9a2", label: "Data Science - R" },
    { value: "63807930b90bd8796e948dd4", label: "Django" },
    { value: "637deeed7599831512c7c760", label: "Docker" },
    { value: "63808590b90bd8796e94928b", label: "Drupal 8" },
    { value: "63808fc0b90bd8796e94b2ac", label: "Elastic Stack" },
    { value: "6380b2e3b90bd8796e94c291", label: "Elasticsearch" },
    { value: "6380c3d7b90bd8796e94c5d1", label: "Flutter" },
    { value: "6380cfdeb90bd8796e94c839", label: "GCP" },
    { value: "63837715b90bd8796e94f1de", label: "Git" },
    { value: "63812f41b90bd8796e94d779", label: "Go" },
    { value: "63836276b90bd8796e94ee4b", label: "Hadoop Ecosystem" },
    { value: "63836841b90bd8796e94efa3", label: "Hibernate" },
    { value: "637dd5317599831512c7bb89", label: "JAVA" },
    { value: "63837e39b90bd8796e94f2d7", label: "Jenkins / Nexus" },
    { value: "6383b076b90bd8796e94f499", label: "Kafka" },
    { value: "6389e4ac5d1dc2c31758987e", label: "Kotlin" },
    { value: "6388ec485d1dc2c317585edb", label: "Kubernetes" },
    { value: "6389dd8e5d1dc2c317588f2f", label: "Laravel" },
    { value: "6389caa45d1dc2c317588139", label: "Linux Administration" },
    { value: "6389c6105d1dc2c31758781b", label: "Matlab" },
    { value: "6389bfc65d1dc2c317587794", label: "MongoDB" },
    { value: "6388c1b3218aa6b2261399d6", label: "Network Administration" },
    { value: "6381ef4cb90bd8796e94de5d", label: "PHP" },
    { value: "6388bc32218aa6b226139608", label: "Power BI" },
    { value: "6382a66eb90bd8796e94eb45", label: "PowerShell" },
    { value: "6381f44ab90bd8796e94def0", label: "Python 3" },
    { value: "6388b378218aa6b226138c76", label: "QA / Test (ISTQB)" },
    { value: "63829ce5b90bd8796e94eaaa", label: "React Native" },
    { value: "63889f3b218aa6b226137fbb", label: "Robot Framework" },
    { value: "63827df8b90bd8796e94e9f1", label: "Ruby" },
    { value: "63836e37b90bd8796e94f0dd", label: "SQL" },
    { value: "63827760b90bd8796e94e918", label: "Scala" },
    { value: "638263d6b90bd8796e94e7e9", label: "Selenium" },
    { value: "63825c15b90bd8796e94e760", label: "Spring Framework" },
    { value: "638249e5b90bd8796e94e63f", label: "Swift" },
    { value: "63824263b90bd8796e94e596", label: "Symfony" },
    { value: "6388660644df715e1cc60633", label: "Tableau" },
    { value: "63823089b90bd8796e94e47d", label: "Talend" },
    { value: "638893cb218aa6b226137e71", label: "Terraform" },
    { value: "63822010b90bd8796e94e289", label: "TypeScript" },
    { value: "63822737b90bd8796e94e3bd", label: "Vue" },
    { value: "638889f4218aa6b2261378ef", label: "Windows Administration" },
    { value: "63a1b2681ce585ca504eefb6", label: "Ingénieur système et réseau" },
    { value: "63db99a85d2fec661d56d9bc", label: "Exchange 2010" },
    { value: "63db91c9f2b4280df3762e6b", label: "Office 365" },
    { value: "63db8837f2b4280df376273a", label: "Windows Server" },
    { value: "63db7303f2b4280df376169d", label: "Active Directory" },
    { value: "649ef059cca8fbcca248dc9a", label: "Réseaux" },
    { value: "649ef3d2cca8fbcca248ddbe", label: "Communication" },
    ],
  profileTypeInitialLabel: 'Python',
  profileTypeInitialValue: '62ceb733544d9f0ae2a4df3f'
}

export default config;
